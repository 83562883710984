import React, { ChangeEvent, useCallback } from 'react'

import { useForm } from '../../../hooks/useForm'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import FormControl from '@material-ui/core/FormControl'

import AddCircleIcon from '@material-ui/icons/AddCircle'
import { Box, makeStyles, Grid, FormHelperText, NativeSelect, Tooltip } from '@material-ui/core'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CloseIcon from '@material-ui/icons/Close'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline'
import { formIsError, userSelector } from '../../../redux/userReducer'
import { useDispatch, useSelector } from 'react-redux'
import { trowError, trowErrorMessage } from '../../../utils/errors'
import { permissionSelector } from '../../../redux/permissionReducer'
import { roleSelector } from '../../../redux/roleReducer'
import {
  assignmentSelector,
  handlerFetchAssignments
} from '../../../redux/assignmentReducer'
import Preloader from '../../Common/Preloader'
import {
  UserData,
  IRole,
  UserBalance,
  AssigmentType,
  IUser,
  ICountry,
  ICity,
  IPermission,
} from '../../../interfaces'
import SwitchStatus from '../../Common/SwitchStatus'
import ErrorText from '../../Common/ErrorText'
import { authSelector } from '../../../redux/authReducer'
import CityForm from '../../Halls/Form/City'
import Overlay from '../../Common/Overlay/index'
import { useShowBlock } from '../../../hooks/useShowBlock'
import CountryForm from './Country'
import { countrySelector } from '../../../redux/countryReducer'
import { citySelector } from '../../../redux/cityReducer'
import { useTranslation } from 'react-i18next'
import PasswordField from '../../Common/PasswordField'
import FieldTimezone from './field-timezone'
import { findPermissionOr } from '../../../utils/find'

const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
    color: '#666'
  },
  textFields: {
    width: '100%'
  },
  permissions: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '100%'
  },
  permission: {
    margin: '.5vh'
  },
  submitBtn: {
    margin: '1vh'
  }
}));

interface UserFormProps {
  onSubmit: (
    data: UserData,
    assignmentsData:
      | string[]
      | {
        prev: string[]
        new: string[]
      },
    hideForm: () => void,
    id: number | undefined
  ) => void
  id?: number
  login?: string
  parent_id?: number | null
  balance?: UserBalance
  first_name?: string | null | undefined
  middle_name?: string | null | undefined
  last_name?: string | null | undefined
  country_id?: number | null | undefined
  city_id?: number | null | undefined
  status?: boolean
  phones?: string[]
  emails?: string[]
  cancelBtnText?: string
  cancelBtnEvent?: () => void
  submitBtnText?: string
  me?: boolean
  hideForm: (user_id?: number) => void
  error?: string[]
  timezone?: number
}

const getUserPermissinsOptions = (role: string, possiblePermissions: string[]) => {
  let ret: string[] = [];
  if (role === 'cashier') {
    ret = ['createGamblingTable', 'updateGamblingTable', 'deleteGamblingTable', 'viewTableMathChildren', 'editTableMathChildren', 'hallIndexAccountShort', 'hallReportAmountCashier', 'showCashboxHistory'];
  } else if (role === 'manager') {
    ret = ['showChildrenHallMath', 'updateChildrenHallMath', 'hallChildrenUpdateLimit', 'jackpotCreate', 'jackpotUpdateChildren', 'jackpotDeleteChildren', 'viewTableMathChildren', 'editTableMathChildren', 'hallChildrenPackage', 'showApi'];
  } else if (role === 'owner') {
    ret = ['jackpotCreate', 'jackpotUpdateChildren', 'jackpotDeleteChildren', 'viewTableMathChildren', 'editTableMathChildren', 'hallChildrenPackage'];
  }
  if (possiblePermissions.includes('updateHallLimit') && !possiblePermissions.includes('hallUpdateLimit')) {
    possiblePermissions = [...possiblePermissions, 'hallUpdateLimit'];
  }
  if (possiblePermissions.includes('updateHallPackage')) {
    possiblePermissions = [...possiblePermissions, 'hallChildrenPackage'];
  }
  console.log(ret);
  return ret.filter(name => possiblePermissions.includes(name) || possiblePermissions.includes(name.replace(/(Children|Short|Cashier)$/, "")));
};

const UserForm: React.FC<UserFormProps> = ({
  onSubmit,
  id,
  login,
  parent_id = null,
  balance,
  first_name,
  last_name,
  middle_name,
  country_id,
  city_id,
  status,
  cancelBtnText,
  cancelBtnEvent,
  submitBtnText,
  me,
  hideForm,
  timezone = 10800
}) => {
  const { form, handleInputChange, setFormItem } = useForm({
    login: !login ? '' : login,
    balance: !balance ? '' : balance,
    status: !status ? '' : status,
    first_name: !first_name ? '' : first_name,
    last_name: !last_name ? '' : last_name,
    middle_name: !middle_name ? '' : middle_name,
    new_password: '',
    confirm_password: '',
    email: '',
    phone: '',
    timezone: 10800
  })
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  const {
    form: { error, loading: formLoading },
  } = useSelector(userSelector)
  const { data: authInfo, assigments: { items } } = useSelector(authSelector)
  React.useEffect(() => {
    id && dispatch(handlerFetchAssignments(id))
  }, [dispatch, id])

  const { items: assignments, loading: assignmentsLoading } = useSelector(assignmentSelector)
  const {
    roles: { items: roles },
  } = useSelector(roleSelector)
  const { items: permissionsList } = useSelector(permissionSelector)
  const { items: countryList } = useSelector(countrySelector)
  const { items: cityList } = useSelector(citySelector)
  const {
    users: { data: users },
  } = useSelector(userSelector)

  const userRole = React.useCallback((assignments: AssigmentType) => {
    let role: string | null = null
    for (let assignment in assignments) {
      if (assignments[assignment] === 'role') {
        role = assignment
      }
    }
    return role
  }, [])
  const userPermissions = React.useCallback(
    (assignments: AssigmentType) => {
      let permissions: string[] = []
      for (let assignment in assignments) {
        if (assignments[assignment] === 'permission') {
          if (permissionsList.find((i: { name: string }) => i.name === assignment)) {
            permissions = [...permissions, assignment]
          }
        }
      }
      return permissions
    },
    [permissionsList]
  )
  const getUserRootParent = (parent_id: any) => {
    let id: number | undefined = 0

    const userRootParent = (parent_id: number | undefined) => {
      const user = users.find((i) => i.id === parent_id)
      if (!!user?.parent_id) {
        !!user?.parent_id && userRootParent(user?.parent_id)
      } else {
        id = parent_id
      }
    }
    userRootParent(parent_id)

    return id
  }

  const [country, setCountry] = React.useState<number | undefined | null>(country_id);
  const [city, setCity] = React.useState<number | undefined | null>(city_id);
  const [selectedParentId, setSelectedParentId] = React.useState<number | null>(null);
  const [role, setRole] = React.useState<string | undefined | null>('');
  const [permissionsSelected, setPermissionsSelected] = React.useState<string[]>([]);
  const [permissions, setPermissions] = React.useState<string[]>([]);
  const [selectedTimezone, setSelectedTimezone] = React.useState<number>(timezone);
  const changeRole = useCallback((role: string, permissions: string[] = []) => {
    const userPermissionsOptions = getUserPermissinsOptions(role, items);
    setPermissions(userPermissionsOptions);
    setPermissionsSelected(permissions.filter(p => userPermissionsOptions.includes(p)));
    setRole(role);
  }, [items]);
  React.useEffect(() => {
    authInfo && setSelectedParentId(id ? parent_id : authInfo?.id);
    // eslint-disable-next-line
  }, [parent_id, authInfo]);

  React.useEffect(() => {
    assignments && changeRole(userRole(assignments.assigned)!, userPermissions(assignments.assigned));
    // return () => changeRole('')
  }, [assignments, userRole, userPermissions, changeRole])


  const data: UserData = {
    login: `${form.login}`,
    first_name: `${form.first_name}`,
    middle_name: `${form.middle_name}`,
    last_name: `${form.last_name}`,
    new_password: `${form.new_password}`,
    confirm_password: `${form.confirm_password}`,
    parent_id: !!selectedParentId ? selectedParentId : null,
    timezone: selectedTimezone,
    city_id: city,
    country_id: country,
    status: form.status ? 10 : 0,
  };

  const filteredAssignments = (prevItems: AssigmentType, newItems: string[]) => {
    let newArr: string[] = [],
      prevArr: string[] = []
    for (let key in prevItems) {
      if (!newItems.includes(key)) prevArr = [...prevArr, key]
    }
    newItems.forEach((item: any) => {
      if (!prevItems[item]) {
        newArr = [...newArr, item]
      }
    })
    return {
      prev: [],
      new: newItems,
    }
  }

  const { showBlock: showCity, isBlockShown: isCity, hideBlock: hideCity } = useShowBlock()
  const { showBlock: showCountry, isBlockShown: isCountry, hideBlock: hideCountry } = useShowBlock()

  React.useEffect(() => {
    return () => {
      dispatch(formIsError(null))
    }
    // eslint-disable-next-line
  }, [])

  interface KeyboardEvent {
    code: string
  }
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      hideForm()
    }
    if (e.code === 'Enter') {
      onSubmit(data, assignmentsData, hideForm, id)
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line
  }, [])

  const rolesOptions = (roles ? roles.map((item: IRole) => <option value={item.name} key={item.name}> {item.description} </option>) : []);
  if (!role) {
    rolesOptions.unshift(<option value="" key=""> </option>);
  }
  const assignmentsData = assignments ? filteredAssignments(assignments.assigned, role ? [role, ...permissionsSelected] : [...permissionsSelected]) : role ? [role, ...permissionsSelected] : [...permissionsSelected]
  return formLoading ? <Preloader absolute={false} /> : (
    <form onSubmit={(e) => { e.preventDefault(); onSubmit(data, assignmentsData, hideForm, id); }}>
      {me && <>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <AccountCircleIcon fontSize="large" />
            <h2>{login}</h2>
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton type="submit">
              <DoneOutlineIcon />
            </IconButton>
            <IconButton onClick={cancelBtnEvent}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <br />
      </>}
      <Grid container spacing={3}>
        <Grid item xs={12} md={authInfo?.id !== id ? 6 : 12}>
          <TextField
            className={classes.textFields}
            label={t('ru.users.fields.login')}
            type="text"
            value={form.login}
            name="login"
            onChange={handleInputChange}
            error={!!trowError(error, 'login')}
            helperText={trowErrorMessage(error, 'login')}
          />
        </Grid>
        {authInfo?.id !== id && (
          <Grid item xs={12} md={4}>
            <Box
              className={classes.textFields}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              onClick={() => setFormItem('status', !form.status)}
              style={{ cursor: 'pointer' }}
            >
              <SwitchStatus checked={!!form.status} />
            </Box>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={6}>
          <PasswordField
            className={classes.textFields}
            label={t('ru.users.fields.password')}
            type="password"
            name="new_password"
            value={form.new_password}
            onChange={handleInputChange}
            error={!!trowError(error, 'new_password')}
            helperText={trowErrorMessage(error, 'new_password')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <PasswordField
            className={classes.textFields}
            label={t('ru.users.fields.repeat_password')}
            type="password"
            name="confirm_password"
            value={form.confirm_password}
            onChange={handleInputChange}
            error={!!trowError(error, 'confirm_password')}
            helperText={trowErrorMessage(error, 'confirm_password')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth size="small" error={!!trowError(error, 'country_id')}>
            <Tooltip title={String(t('ru.common.add_btn'))} arrow>
              <Button className={classes.buttons} onClick={showCountry}>
                {t('ru.users.fields.country')} <AddCircleIcon />
              </Button>
            </Tooltip>
            <NativeSelect onChange={(e: ChangeEvent<HTMLSelectElement>) => setCountry(Number(e.target.value))} value={!!country ? country : ''} >
              <option aria-label="None" value="" />
              {countryList && countryList.map((item: ICountry) => <option value={item.id} key={item.id}> {item.name} </option>)}
            </NativeSelect>
            <FormHelperText>
              {trowErrorMessage(error, 'country_id') ? trowErrorMessage(error, 'country_id') : t('ru.users.form_helpers.country')}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth size="small" error={!!trowError(error, 'city_id')}>
            <Tooltip title={String(t('ru.common.add_btn'))} arrow>
              <Button className={classes.buttons} onClick={showCity}>
                {t('ru.users.fields.city')} <AddCircleIcon />
              </Button>
            </Tooltip>
            <NativeSelect onChange={(e: ChangeEvent<HTMLSelectElement>) => setCity(Number(e.target.value))} value={!!city ? city : ''} >
              <option aria-label="None" value="" />
              {cityList && cityList.map((item: ICity) => <option value={item.id} key={item.id}> {item.name} </option>)}
            </NativeSelect>
            <FormHelperText>
              {trowErrorMessage(error, 'city_id') ? trowErrorMessage(error, 'city_id') : 'Выберите город'}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth size="small" error={!!trowError(error, 'parent_id')}>
            <FormHelperText>{t('ru.users.fields.parent')}</FormHelperText>
            <NativeSelect onChange={(e) => setSelectedParentId(Number(e.target.value))} value={!!selectedParentId ? selectedParentId : ''} >
              <option aria-label="None" value="" />
              {users && users.map((item: IUser) => id !== item.id && getUserRootParent(item.parent_id) !== id && <option value={item.id} key={item.id}> {item.login} </option>)}
            </NativeSelect>
            <FormHelperText>
              {trowErrorMessage(error, 'parent_id') ? trowErrorMessage(error, 'parent_id') : ''}
            </FormHelperText>
          </FormControl>
        </Grid>
        <FieldTimezone error={!!trowError(error, 'timezone')} value={data.timezone} onChange={setSelectedTimezone} label={t('ru.users.fields.timezone')} formHelperText={trowErrorMessage(error, 'timezone')} />
        {assignmentsLoading ? <Preloader absolute={false} /> : <>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth size="small">
              <FormHelperText>{t('ru.users.fields.role')}</FormHelperText>
              <NativeSelect onChange={(e) => changeRole(e.target.value)} value={role}>
                {rolesOptions}
              </NativeSelect>
            </FormControl>
          </Grid>
          {findPermissionOr(items, ['assignAssignment', 'assignAssignmentChildren']) && permissions.length ? <Grid item xs={12} sm={12}>
            <FormControl fullWidth size="small">
              <FormHelperText>Permissions</FormHelperText>
              {permissions.map((p) => <label key={p}>{permissionsList.find((i: IPermission) => i.name === p)?.description ?? p} <input type="checkbox" checked={permissionsSelected.includes(p)} onChange={() => setPermissionsSelected(permissionsSelected.includes(p) ? permissionsSelected.filter(x => x !== p) : [...permissionsSelected, p])} /></label>)}
            </FormControl>
          </Grid> : null}
        </>}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            {cancelBtnText && <Button fullWidth className={classes.submitBtn} onClick={cancelBtnEvent} variant="contained">
              {cancelBtnText}
            </Button>}
            {submitBtnText && <Button fullWidth className={classes.submitBtn} variant="contained" type="submit">
              {submitBtnText}
            </Button>}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ErrorText error={error} />
        </Grid>
      </Grid>
      {isCity && <Overlay>
        <CityForm hideCity={hideCity} />
      </Overlay>}
      {isCountry && <Overlay>
        <CountryForm hideCountry={hideCountry} />
      </Overlay>}
    </form>
  )
}

export default UserForm
